import React from 'react';

const ZeroPercent = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="56.909"
    height="40.65"
    viewBox="0 0 56.909 40.65"
  >
    <defs>
      <clipPath>
        <rect width="56.909" height="40.65" fill="currentColor" />
      </clipPath>
    </defs>
    <g transform="translate(0 0)">
      <g transform="translate(0 0)" clipPath="url(#clip-path)">
        <path
          d="M0,20.409a54.853,54.853,0,0,1,.673-9.251A20.34,20.34,0,0,1,2.691,4.85,9.107,9.107,0,0,1,6,1.261,8.713,8.713,0,0,1,10.485.112a9.2,9.2,0,0,1,4.681,1.149,8.675,8.675,0,0,1,3.28,3.588,20.527,20.527,0,0,1,1.906,6.307,59.764,59.764,0,0,1,.616,9.251,54.744,54.744,0,0,1-.672,9.279,20.327,20.327,0,0,1-2.018,6.279A9.188,9.188,0,0,1,15,39.556a8.567,8.567,0,0,1-4.457,1.149A9.137,9.137,0,0,1,5.8,39.528a8.836,8.836,0,0,1-3.28-3.644A20.811,20.811,0,0,1,.616,29.576,58.7,58.7,0,0,1,0,20.409m4.2,0q0,8.018,1.507,12.335t4.857,4.317q3.236,0,4.716-3.953t1.479-12.7q0-7.961-1.423-12.307T10.456,3.756q-3.238,0-4.744,3.924T4.2,20.409"
          transform="translate(0 -0.056)"
          fill="currentColor"
        />
        <path
          d="M52.367,9.587a15.976,15.976,0,0,1,.529-4.4,7.982,7.982,0,0,1,1.5-2.971A5.616,5.616,0,0,1,56.678.56a7.732,7.732,0,0,1,2.809-.5A8.157,8.157,0,0,1,62.3.532a5.588,5.588,0,0,1,2.28,1.57,7.427,7.427,0,0,1,1.5,2.943,16.774,16.774,0,0,1,.528,4.541,16.774,16.774,0,0,1-.528,4.541,7.429,7.429,0,0,1-1.5,2.944,5.582,5.582,0,0,1-2.28,1.569,8.521,8.521,0,0,1-5.619,0,5.572,5.572,0,0,1-2.28-1.569,7.436,7.436,0,0,1-1.5-2.944,16.772,16.772,0,0,1-.529-4.541M78.327,0l2.635,1.682L57.077,40.649l-2.635-1.682ZM55.955,9.587q0,3.646.989,5.046a3.017,3.017,0,0,0,2.571,1.4,3.6,3.6,0,0,0,1.441-.28,2.491,2.491,0,0,0,1.1-.981,5.78,5.78,0,0,0,.706-1.962,16.747,16.747,0,0,0,.255-3.224,16.385,16.385,0,0,0-.255-3.167,6.117,6.117,0,0,0-.706-1.99,2.467,2.467,0,0,0-1.1-1.009,3.613,3.613,0,0,0-1.441-.28,3.753,3.753,0,0,0-1.5.28,2.459,2.459,0,0,0-1.1,1.009,6.092,6.092,0,0,0-.707,1.99,16.389,16.389,0,0,0-.255,3.167m12.9,20.8a15.978,15.978,0,0,1,.528-4.4,7.975,7.975,0,0,1,1.5-2.971,5.615,5.615,0,0,1,2.28-1.654,7.736,7.736,0,0,1,2.809-.5,8.162,8.162,0,0,1,2.81.476,5.6,5.6,0,0,1,2.28,1.57,7.434,7.434,0,0,1,1.5,2.943,16.774,16.774,0,0,1,.528,4.541,16.771,16.771,0,0,1-.528,4.541,7.436,7.436,0,0,1-1.5,2.944,5.589,5.589,0,0,1-2.28,1.569,8.522,8.522,0,0,1-5.619,0,5.571,5.571,0,0,1-2.28-1.569,7.43,7.43,0,0,1-1.5-2.944,16.772,16.772,0,0,1-.528-4.541m3.588,0q0,3.646.989,5.046A3.018,3.018,0,0,0,76,36.837a3.593,3.593,0,0,0,1.441-.28,2.488,2.488,0,0,0,1.1-.981,5.769,5.769,0,0,0,.706-1.962,16.747,16.747,0,0,0,.255-3.224,16.388,16.388,0,0,0-.255-3.167,6.105,6.105,0,0,0-.706-1.99,2.464,2.464,0,0,0-1.1-1.009A3.61,3.61,0,0,0,76,23.941a3.758,3.758,0,0,0-1.5.281,2.462,2.462,0,0,0-1.1,1.009,6.1,6.1,0,0,0-.707,1.99,16.4,16.4,0,0,0-.254,3.167"
          transform="translate(-26.184 0)"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default ZeroPercent;
